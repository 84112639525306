'use client';
import React from 'react';
import { ErrorComponent } from '@/components';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <ErrorComponent reset={reset} error={error} />
      </body>
    </html>
  );
}
